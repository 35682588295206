import { create } from "zustand";
import { Sort, SortedColumn } from "../components/DataViewerCtr";
import { CellEdits } from "../components/EditDataViewer";

interface DataViewerCtrState {
  editDataViewerKey: number,
  setEditDataViewerKey: (editDataViewerKey: number) => void
  newEdits: string[],
  setNewEdits: (newEdits: string[]) => void
  showSingleClickEdit: boolean,
  setShowSingleClickEdit: (showSingleClickEdit: boolean) => void
  searchText: string,
  setSearchText: (searchText: string) => void
  focusedSampleId: string | undefined
  setFocusedSampleId: (focusedSampleId: string | undefined) => void
  selectedSampleIds: string[]
  setSelectedSampleIds: (selectedSampleIds: string[]) => void
  cellEdits: CellEdits
  errorMessage: string
  setErrorMessage: (errorMessage: string) => void
  setCellEdits: (cellEdits: CellEdits) => void
  sort?: Sort
  viewLoading: boolean
  unselectafterdelete: boolean
  sortedColumn: SortedColumn | undefined
  setViewLoading: (loading: boolean) => void
  setUnselectafterdelete: (unselectafterdelete: boolean) => void
  setSortedColumn: (sortedColumn: SortedColumn | undefined) => void
  setSort: (sort: Sort) => void
  columnFilters: { [key: string]: string }
  setColumnFilters: (columnFilter: { [key: string]: string }) => void
  isExportDialogOpen: boolean
  setIsExportDialogOpen: (isExportDialogOpen: boolean) => void
  totalSamples: number | undefined
  setTotalSamples : (totalSamples: number | undefined) => void
}

export const useDataViewerCtrStore = create<DataViewerCtrState>((set) => ({
  editDataViewerKey: 0,
  setEditDataViewerKey: (editDataViewerKey: number) => set({ editDataViewerKey }),
  newEdits: [],
  setNewEdits: (newEdits: string[]) => set({ newEdits }),
  showSingleClickEdit: false,
  setShowSingleClickEdit: (showSingleClickEdit: boolean) => set({ showSingleClickEdit }),
  searchText: "",
  setSearchText: (searchText: string) => set({ searchText }),
  focusedSampleId: undefined,
  setFocusedSampleId: (focusedSampleId: string | undefined) => set({ focusedSampleId }),
  selectedSampleIds: [],
  setSelectedSampleIds: (selectedSampleIds: string[]) => set({ selectedSampleIds }),
  cellEdits: {},
  errorMessage: "",
  setErrorMessage: (errorMessage: string) => set({ errorMessage }),
  setCellEdits: (cellEdits: CellEdits) => set({ cellEdits }),
  viewLoading: true,
  sort: undefined,
  setSort: (sort: Sort) => set({ sort }),
  sortedColumn: undefined,
  unselectafterdelete: false,
  setViewLoading: (viewLoading: boolean) => set({ viewLoading }),
  setUnselectafterdelete: (unselectafterdelete: boolean) => set({ unselectafterdelete }),
  setSortedColumn: (sortedColumn: SortedColumn | undefined) => set({ sortedColumn }),
  columnFilters: {},
  setColumnFilters: (columnFilters: { [key: string]: string }) => set({ columnFilters }),
  isExportDialogOpen: false,
  setIsExportDialogOpen: (isExportDialogOpen: boolean) => set({ isExportDialogOpen }),
  totalSamples: undefined,
  setTotalSamples: (totalSamples) => set({ totalSamples })
}))